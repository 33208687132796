<template>
<v-menu :close-on-content-click="isDialogClose" origin="center center" :nudge-bottom="10" transition="scale-transition">
    <template v-slot:activator="{on}">
        <v-btn icon large text v-on="on">
            <v-avatar size="36px">
                <img v-if="avatar" :src="avatar" alt="name">
                <v-icon v-else x-large class="blue--text">
                    mdi-account
                </v-icon>
            </v-avatar>
        </v-btn>
    </template>
    <v-list class="pa-0">
        <v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <img v-if="avatar" :src="avatar" alt="name">
                    <v-icon v-else x-large class="blue--text">
                        mdi-account
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user }}</v-list-item-subtitle>
                </v-list-item-content>

                <!--<v-list-item-action>-->
                <!--<v-btn-->
                <!--:class="fav ? 'red&#45;&#45;text' : ''"-->
                <!--icon-->
                <!--@click="fav = !fav"-->
                <!--&gt;-->
                <!--<v-icon>mdi-heart</v-icon>-->
                <!--</v-btn>-->
                <!--</v-list-item-action>-->
            </v-list-item>
        </v-list>
        <v-divider />
        <v-dialog v-model="dialogProfile" max-width="500px" persistent>
            <v-card>
                <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeLoadingDialog" />
                <v-card-title class="headline indigo lighten-2">
                    <v-avatar class="mr-4" color="blue">
                        <v-icon dark>
                            mdi-account-circle
                        </v-icon>
                    </v-avatar>
                    <div class="text-xs-center">
                        <span>
                            <h2>USER PROFILE</h2>
                        </span>
                    </div>
                </v-card-title>

                <v-card-text class="mt-7"><b> USER: {{user}} </b></v-card-text>
                <v-card-text><b> NAME: {{name}} </b></v-card-text>
                <v-card-text hidden="true"><b> ROLE: {{whatRole()}}</b></v-card-text>
                <v-card-text hidden="true"><b> DATE CREATED: {{dateCreated()}}</b></v-card-text>
                <v-card-text hidden="true"><b> LAST UPDATED: {{lastUpdated()}}</b></v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog">
                        Close
                    </v-btn>
                    <v-btn color="primary" text @click="displayPasswordForm">
                        Change Password
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="changePasswordForm" max-width="500px" persistent>
            <v-card ref="formCard">
                <v-card-title class="headline indigo lighten-2">
                    <v-avatar class="mr-4" color="blue">
                        <v-icon dark>
                            mdi-account-circle
                        </v-icon>
                    </v-avatar>
                    <div class="text-xs-center">
                        <span>
                            <h2>CHANGE PASSWORD</h2>
                        </span>
                    </div>
                </v-card-title>

                <v-card-text class="mt-7">
                    <v-form ref="formEntry">
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field label="Current Password*" v-model="form.currentPassword" required :rules="[rules.requiredField]" :append-icon="passwordType.currentPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.currentPassword = !passwordType.currentPassword)" :type="passwordType.currentPassword ? 'password' : 'text'"></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field label="New Password*" v-model="form.newPassword" required :rules="[rules.requiredField]" :append-icon="passwordType.newPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.newPassword = !passwordType.newPassword)" :type="passwordType.newPassword ? 'password' : 'text'"></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field label="Confirm Password*" v-model="form.confirmPassword" required :rules="[rules.requiredField, rules.matchPassword]" :append-icon="passwordType.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.confirmPassword = !passwordType.confirmPassword)" :type="passwordType.confirmPassword ? 'password' : 'text'"></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" text @click="changePassword">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-list-item :close-on-content-click="false" v-for="(item,index) in menuitems" :key="index" :to="!item.href ? {name: item.name} : null" :href="item.href" ripple="ripple" :disabled="item.disabled" :target="item.target" rel="noopener" @click="item.click">
            <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</v-menu>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'

export default {
    name: 'AppProfile',
    components: {
        Loading,
    },
    data() {
        return {
            dialogProfile: false,
            isDialogClose: true,
            changePasswordForm: false,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isHtml: false,
            passwordType: {
                currentPassword: String,
                newPassword: String,
                confirmPassword: String
            },
            form: {
                currentPassword: undefined,
                newPassword: undefined,
                confirmPassword: undefined
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                matchPassword: v => v === this.form.newPassword || "Does not match new password.",
            },
            menuitems: [{
                    icon: 'mdi-account',
                    href: '#',
                    title: 'toolbar.profile',
                    disabled: false,
                    click: () => {
                        this.showDialog();
                    },
                },
                {
                    icon: 'mdi-settings',
                    href: '#',
                    title: 'toolbar.settings',
                    disabled: true,
                    click: () => {
                        console.log('this.toggleSettingsPanel()');
                        this.toggleSettingsPanel();
                    },
                },
                {
                    icon: 'mdi-exit-to-app',
                    href: '#',
                    title: 'toolbar.logout',
                    disabled: !this.$keycloak.authenticated,
                    click: () => {
                        this.logout();
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'user',
            'name',
            'avatar',
            'status',
            'attributes',
            'role',
        ]),

    },
    methods: {
        whatRole() {
            // const attributes = this.attributes ? JSON.parse(this.attributes) : {}
            // const permissionRoles = attributes ? attributes.role ?? [] : []
            // if (permissionRoles && permissionRoles.length > 0) {
            //     return permissionRoles[0].toUpperCase()
            // }
            return "N/A"
        },
        dateCreated() {
            // const attributes = this.attributes ? JSON.parse(this.attributes) : {}
            // const lastUpdated = attributes ? attributes.lastUpdated ?? [] : []
            // if (lastUpdated && lastUpdated.length > 0) {
            //     return lastUpdated[0].toUpperCase()
            // }
            return "N/A"
        },
        lastUpdated() {
            // const attributes = this.attributes ? JSON.parse(this.attributes) : {}
            // const dateCreated = attributes ? attributes.dateCreated ?? [] : []
            // if (dateCreated && dateCreated.length > 0) {
            //     return dateCreated[0].toUpperCase()
            // }
            return "N/A"
        },
        showDialog() {
            this.dialogProfile = true
            this.isDialogClose = false
        },
        closeDialog() {
            if (this.$refs.formEntry) {
                this.$refs.formEntry.reset()
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
            this.dialogProfile = false
            this.changePasswordForm = false
            this.isDialogClose = true
        },
        closeLoadingDialog(type) {
            if (type === 'loading') {
                this.loadingDialog = false
                this.closeDialog()
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        displayPasswordForm() {
            console.log('pass')
            this.dialogProfile = false
            this.changePasswordForm = true
        },
        async changePassword() {
            if (this.$refs.formEntry.validate()) {
                this.loadingDialog = true
                this.isHtml = true
                this.loadingFinished = false
                const parameters = {
                    username: this.user,
                    oldPassword: this.form.currentPassword,
                    newPassword: this.form.newPassword
                }
                this.loadingMessage = `Updating account password.`
                const updatePasswordResponse = await API.updateAccountPassword(parameters)
                this.loadingFinished = true
                if (!updatePasswordResponse || updatePasswordResponse.error) {
                    this.isHtml = false
                    this.loadingMessage = updatePasswordResponse ? updatePasswordResponse.error : "Error updating password."
                } else {
                    this.loadingMessage = `Successfully updated your password.`
                    this.closeDialog()
                }
            }
        },
        logout() {
            // this.$store.dispatch('LogOut');
            // this.$router.push('/signin');
            this.$keycloak.logoutFn({
                logoutRedirectUri: window.location.host,
            });
        },
        toggleSettingsPanel() {
            console.log('this.toggleSettingsPanel()');
            this.$vuetify.goTo(0);
            this.$store.dispatch('SettingsPanelToggle');
        },
    },
};
</script>
