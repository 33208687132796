import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import axios from 'axios';
import VueAxios from 'vue-axios';

import vuetify from './plugins/vuetify';
import i18n from './locale'; // Internationalization

import App from './App.vue';
import router from './router';
import store from './store';

import './router/permission';
import AsyncComputed from 'vue-async-computed'

Vue.use(AsyncComputed)

Vue.use(VueAxios, axios);

// new Vue({
//   router,
//   store,
//   i18n,
//   vuetify,
//   render: (h) => h(App),
// }).$mount('#app');

Vue.use(VueKeyCloak, {
  init: {
    onLoad: 'login-required',
  },
  config: {
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  },
  logout: {
    redirectUri: process.env.VUE_APP_URL
  },
  onReady: () => {
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  },
});
