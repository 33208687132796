<template>
<v-navigation-drawer color="primary" fixed app :value="navbarShow" width="289" @input="stateNavbarShow">
    <v-app-bar v-if="navbarLogo" :dense="toolbarDense" height="64" color="white" flat>
        <v-app-bar-nav-icon @click.stop="toggleNavbar" class="button-hanged">
            <v-icon x-large>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <v-img src="/img/PayConnect.png" alt="Pay-Connect Logo" contain max-height="25" max-width="200" />
    </v-app-bar>
    <div class="left-notch">
        <the-layout-drawer-list :dense="navbarDense" :routes="permissionRoutes" :authorities="authorities" icon-show />
    </div>
</v-navigation-drawer>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import TheLayoutDrawerList from './TheLayoutDrawerList.vue';

export default {
    name: 'TheLayoutDrawer',
    components: {
        TheLayoutDrawerList,
    },
    data: () => ({
        routes: [],
    }),
    computed: {
        ...mapGetters([
            'permissionRoutes',
            'navbarDense',
            'navbarShow',
            'navbarLogo',
            'toolbarDense',
            'name',
            'avatar',
            'authorities'
        ]),
        avatarToUse() {
            if (this.avatar) {
                return this.avatar;
            }

            return '/img/avatars/default.png';
        },
    },
    mounted() {
        console.log(this.permissionRoutes)
        // this.$http.get(`${process.env.VUE_APP_NAVIGATION_API}/navigation`)
        //     .then(({
        //         data
        //     }) => {
        //         this.routes = data;
        //     });
    },
    methods: {
        stateNavbarShow(state) {
            this.$store.dispatch('NavbarState', {
                state
            });
        },
        toggleNavbar() {
            this.$store.dispatch('NavbarToggle');
        },
    },
};
</script>

<style lang="scss" scoped>
.left-notch {
    background-color: #fff;

    &::before {
        content: "";
        width: 6px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
    }
}

.button-hanged {
    position: absolute !important;
    top: 8px;
    left: 18px;
}
</style>
